import { Link } from 'react-router-dom'
import Logo from "../logo.png";
import React from "react";

const Sidebar = () => {
    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <aside className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 "
                   id="sidenav-main">
                <div className="sidenav-header">
                    <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                       aria-hidden="true" id="iconSidenav"></i>

                    <Link to="/" className="nav-link">
                        <img src={Logo} className="navbar-brand-img height-200" alt="main_logo" />
                        <span className="ms-1 font-weight-bold">Metaffice</span>
                    </Link>
                </div>
                <hr className="horizontal dark mt-0"/>
                <div className="collapse navbar-collapse w-auto h-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#managemen" aria-controls="managemen"
                            role="button" className="nav-link" aria-expanded="false">
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className="ni ni-settings-gear-65 text-primary text-sm"></i>
                            </div>
                            <span className="nav-link-text ms-1">Managemen</span>
                        </a>
                        <div className="collapse show" id="managemen">
                            <ul className="nav ms-4">
                                <li className="nav-item ">
                                    <Link to="/lembaga" className="nav-link">
                                        <span className="sidenav-normal">Data Lembaga</span>
                                    </Link>
                                </li>
                                <li className="nav-item ">
                                    <Link to="/karyawan" className="nav-link">
                                        <span className="sidenav-normal">Data Karyawan</span>
                                    </Link>
                                </li>
                                <li className="nav-item ">
                                    <Link to="/mahasiswa" className="nav-link">
                                        <span className="sidenav-normal">Data Mahasiswa</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                </div>
            </aside>
        </>
    );
}

export default Sidebar;