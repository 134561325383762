import axios from "axios";
import { Link } from 'react-router-dom';
import {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import {BearerToken} from "../../components/AuthCheck";
import {api_mahasiswa_list, api_prodi_list} from "../../components/ManagemenServiceAPI";

const TableMahasiswa = () => {
    const url = '/surat/form/'
    const { SearchBar } = Search;
    let [dataProdi, setDataProdi] = useState([]);
    let [dataMhs, setDataMhs] = useState([]);
    let [prodi, setProdi] = useState(0);

    useEffect(() => {
        axios.get(api_prodi_list, {
            headers: {
                'Authorization': BearerToken()
            },

        }).then((response) => {
            setDataProdi(response.data.rows);
            setProdi(response.data.rows[0][0]);

        }).catch(err => console.error('error get_prodi_all: ', err));
    }, [])

    useEffect(() => {
        axios.get(api_mahasiswa_list, {
            headers: {
                'Authorization': BearerToken()
            },
            params: {
                kode_prodi: prodi,
            }

        }).then((response) => {
            setDataMhs(response.data.rows);

        }).catch(err => console.error('error get_mahasiswa_all: ', err));
    }, [prodi])

    const actButton = (cell, row, rowIndex) => {
        return ([
            <Link to={url+rowIndex} className='btn btn-xs btn-primary'>Detail</Link>,
        ]);
    };

    const namaDanNim = (cell, row, rowIndex) => {
        return (
            <>
                {row.nama} <br/>
                Kode Status : {row.kode_status}
            </>
        );
    };

    const columns = [
        {
            text: 'No.',
            dataField: '',
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1;
            }
        }, {
            text: 'NIM',
            dataField: 'id_mahasiswa'
        }, {
            text: 'Nama',
            dataField: 'nama',
            formatter: namaDanNim,
        }, {
            text: 'Prodi',
            dataField: 'prodi.nama'
        }, {
            text: 'Action',
            dataField: 'button',
            formatter: actButton,
        }
    ];

    function change(event){
        setProdi(event.target.value);
        setDataMhs([]);
    }

    return (
        <div className="card-body">
            Prodi:
            {dataProdi.length === 0 ?
                <div className="text-left">
                    <img src="/assets/img/spinners/loading.gif" width="160" />
                </div>
                :
                <select className="form-select" onChange={change}>
                    {dataProdi.map((prodiz) =>
                        <option value={prodiz[0]}>{prodiz[2]} - {prodiz[1]}</option>
                    )}
                </select>
            }
            <br/>
            {dataMhs.length === 0 ?
                <div className="text-center">
                    <img src="/assets/img/spinners/loading.gif" width="160" />
                </div>
                :
                <ToolkitProvider
                    keyField="id_mahasiswa"
                    data={ dataMhs }
                    columns={ columns }
                    search>
                    {props => (
                        <div>
                            <SearchBar { ...props.searchProps } />
                            <hr />
                            <BootstrapTable
                                keyField="id"
                                { ...props.baseProps }
                                pagination={ paginationFactory() }
                                bordered={ false }
                            />
                        </div>
                    )}
                </ToolkitProvider>
            }
        </div>
    );
}

export default TableMahasiswa