import axios from "axios";
import { Link } from 'react-router-dom'
import {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import {BearerToken} from "../../components/AuthCheck";
import {api_karyawan_list} from "../../components/ManagemenServiceAPI";

const TableKaryawan = () => {
    const url = '/surat/form/'
    const { SearchBar } = Search;
    let [data, setData] = useState([]);

    useEffect(() => {
        axios.get(api_karyawan_list, {
            headers: {
                'Authorization': BearerToken(),
                
            },

        }).then((response) => {
            setData(response.data.rows);

        }).catch(err => console.error('error get_karyawan_all: ', err));
    }, [])

    const actButton = (cell, row, rowIndex) => {
        return ([
            <Link to={url+rowIndex} className='btn btn-xs btn-primary'>Detail</Link>,
        ]);
    };

    const columns = [
        {
            text: 'No.',
            dataField: '',
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1;
            }
        }, {
            text: 'NIP',
            dataField: 'nip'
        }, {
            text: 'Nama',
            dataField: 'nama_bergelar',
        }, {
            text: 'Status Kepegawaian',
            dataField: 'status_kepegawaian'
        }, {
            text: 'Action',
            dataField: 'button',
            formatter: actButton,
        }
    ];

    return (
        <div className="card-body">
            {data.length === 0 ?
                <div className="text-center">
                    <img src="/assets/img/spinners/loading.gif" width="160" />
                </div>
                :
                <ToolkitProvider
                    keyField="nip"
                    data={ data }
                    columns={ columns }
                    search>
                    {
                        props => (
                            <div>
                                <SearchBar { ...props.searchProps } />
                                <hr />
                                <BootstrapTable
                                    keyField="id"
                                    { ...props.baseProps }
                                    pagination={ paginationFactory() }
                                    bordered={ false }
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            }
        </div>
    );
}

export default TableKaryawan