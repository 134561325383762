import React from "react";
import {Navigate} from "react-router-dom";

import TableKaryawan from "./TableKaryawan";
import {AuthCheck} from "../../components/AuthCheck";

function Karyawan() {
    if (AuthCheck() === 0){
        return <Navigate replace to="/login"/>;
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Data Karyawan</h5>
                                </div>
                            </div>
                        </div>
                        <TableKaryawan />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Karyawan