import './App.css';

import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, Outlet} from 'react-router-dom';

import Content from "./layouts/Content";
import Login from "./layouts/login/Login";
import Mahasiswa from "./layouts/mahasiswa/Mahasiswa";
import Karyawan from "./layouts/karyawan/Karyawan";
import Lembaga from "./layouts/lembaga/Lembaga";
import Sidebar from "./layouts/Sidebar";
import Navbar from "./layouts/Navbar";

function App() {
    const useScript = (src) => {
        useEffect(() => {
            const tag = document.createElement('script');
            tag.async = true;
            tag.src = src;
            document.body.appendChild(tag);

            return () => {
                document.body.removeChild(tag);
            }
        }, [src])
    }

    useScript('/assets/js/core/popper.min.js');
    useScript('/assets/js/core/bootstrap.min.js');
    useScript('/assets/js/plugins/perfect-scrollbar.min.js');
    useScript('/assets/js/plugins/smooth-scrollbar.min.js');
    useScript('/assets/js/argon-dashboard.min.js');
    useScript('/assets/js/htmx.min.js');

    const LayoutDashboard = () => (
        <>
            <Sidebar />
            <main className="main-content position-relative border-radius-lg ">
                <Navbar />
                <div className="container-fluid py-4">
                    <Outlet />
                </div>
            </main>
        </>
    );

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />

                <Route element={<LayoutDashboard />}>
                    <Route path="/" element={<Content />} />,
                    <Route path="/mahasiswa" element={<Mahasiswa />} />,
                    <Route path="/karyawan" element={<Karyawan />} />,
                    <Route path="/lembaga" element={<Lembaga />} />,
                </Route>
            </Routes>
        </Router>
    )
}

export default App;