import React from "react";
import LoginForm from "./LoginForm";

function Login() {
    return (
        <>
            <div className="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
                 style={{
                     backgroundImage: `url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg')`,
                     backgroundPosition: `top` }}>
                <span className="mask bg-gradient-dark opacity-6"></span>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 text-center mx-auto">
                            <h1 className="text-white mb-2 mt-5">Welcome!</h1>
                            <p className="text-lead text-white">Selamat datang di aplikasi MyUMKT.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
                    <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
                        <div className="card z-index-0">
                            <div className="card-header text-center pt-4">
                                <h5>Login SSO</h5>
                            </div>
                            <LoginForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login